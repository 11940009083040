import React from "react"
import Cookies from "js-cookie"

const defaultState = {
  consent: false,
  accept: () => {},
  decline: () => {},
}

const ConsentToCookiesContext = React.createContext(defaultState)

class CookieConsentProvider extends React.Component {
  state = {
    consent: false,
  }

  accept = () => {
    Cookies.set("gatsby-gdpr-google-analytics", true, { expires: 365 })
    this.setState({ consent: true })
  }

  decline = () => {
    Cookies.set("gatsby-gdpr-google-analytics", false)
    this.setState({ consent: false })
  }

  componentDidMount() {
    const consentFromCookie =
      Cookies.get("gatsby-gdpr-google-analytics") === "true"
    this.setState({ consent: consentFromCookie })
  }

  render() {
    const { children } = this.props
    const { consent } = this.state
    return (
      <ConsentToCookiesContext.Provider
        value={{
          consent: consent,
          accept: this.accept,
          decline: this.decline,
        }}
      >
        {children}
      </ConsentToCookiesContext.Provider>
    )
  }
}

export default ConsentToCookiesContext

export { CookieConsentProvider }
